import { isEmpty } from 'lodash';

import { isDateRange, SearchParameters } from 'api/search/useSearch';
import {
  CommandToolbarProps,
  CsvKeyValue,
  DefaultMdfTypes,
  isAccountType,
} from 'features/command/command-types';
import { type AssignedMember, MdfIds } from 'types';
import { type FieldValue, getLayoutSettings, type Metadata } from 'types/forms/forms';
import {
  DateRangeQL,
  FieldTypeEnum,
  LayoutSettings,
  Mdf,
  MdfField,
  MemberType,
  MMetaDataField,
  RangeBy,
  SearchFilterProps,
  ViewTypes,
} from 'types/graphqlTypes';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

const isDefaultMdfId = (val?: string): val is DefaultMdfTypes => {
  return MdfIds.includes(val as DefaultMdfTypes);
};

export const toSearchParameters = (val: SearchFilterProps): SearchParameters => {
  const defaultMdfId = isDefaultMdfId(val.mdfId) ? val.mdfId : null;

  return {
    searchString: val.searchString ?? '',
    skip: false,
    metadataFilter: val.metaDataFilter,
    toolbarState: {
      defaultMdfId,
      platformTypes: val.instanceTypes ?? [],
      matchAllAssignees: val.matchAllAssignees ?? false,
      restrictedItemsOnly: val.restrictedItemsOnly ?? false,
      semanticSearch: val.semanticSearch ?? false,
      showRestricted: val.showRestricted ?? false,
      assignedIds: val.assignedMemberIds ?? [],
      createdByIds: val.createdByIds ?? [],
      isFiltering: true,
      mdfId: val.mdfId ?? null,
      mTypes: val.types ?? [],
      order: val.order ?? 'desc',
      rangeBy: val.rangeBy ?? null,
      sortBy: val.orderBy ?? 'best',
      statusFilter: val.statusList ?? [],
    },
  };
};

export const toSearchFilterProps = (
  val: CommandToolbarProps,
  searchString: string,
  metaDataFilter: Metadata,
): SearchFilterProps => {
  return {
    types: val.mTypes ?? [],
    instanceTypes: val.platformTypes,
    mdfId: val.defaultMdfId ?? val.mdfId ?? undefined,
    statusList: val.statusFilter,
    assignedMemberIds: val.assignedIds,
    createdByIds: val.createdByIds,
    rangeBy: val.rangeBy ?? undefined,
    orderBy: val.sortBy !== 'best' ? val.sortBy : undefined,
    order: val.order,
    searchString,
    metaDataFilter,
    matchAllAssignees: val.matchAllAssignees ?? false,
    restrictedItemsOnly: val.restrictedItemsOnly ?? false,
    semanticSearch: val.semanticSearch ?? false,
    showRestricted: val.showRestricted ?? false,
  };
};

type FieldMap = Record<string, MdfField & { formId: string; settings: LayoutSettings }>;
const keyDelimiter = '##';
const getFieldKey = (fieldId: string, formId: string) => `${fieldId}${keyDelimiter}${formId}`;
const getFieldMap = (mdfs: Mdf[], view: ViewTypes = 'order_grid'): FieldMap => {
  const fieldMap: FieldMap = {};
  for (const mdf of mdfs) {
    for (const field of mdf.fields) {
      const settings = getLayoutSettings(mdf, field, view);
      fieldMap[`${getFieldKey(field.fieldId, mdf.id)}`] = { ...field, formId: mdf.id, settings };
    }
  }
  return fieldMap;
};

export const getMetadataValue = (metadata: MMetaDataField[] | null | undefined, key: string) => {
  if (!metadata) return '';
  return metadata.find((kv) => kv.key === key)?.value ?? '';
};

/**
 * CMDK will put all content in the data attribute
 * If there are quotes or newlines in there, dina crashes.
 */
export const safeForHTML = (html: string) => {
  return html?.replace(/['"]+/g, '');
};

export const getAccountTitle = (item: MemberType) => {
  if (isAccountType(item.mProperties?.account)) {
    return item.mProperties?.account.accountTitle;
  }
  return '';
};

export const getDateRange = (
  val: RangeBy | null,
): { dateRange: DateRangeQL; key: keyof RangeBy; label: string } | null => {
  if (!val) return null;
  if (val.createdAt) return { dateRange: val.createdAt, key: 'createdAt', label: 'Created' };
  if (val.scheduledAt) return { dateRange: val.scheduledAt, key: 'scheduledAt', label: 'Sched.' };
  if (val.updatedAt) return { dateRange: val.updatedAt, key: 'updatedAt', label: 'Upd.' };
  return null;
};

export const isFiltering = (state: CommandToolbarProps): boolean => {
  if (state.sortBy !== 'best') return true;
  if (state.rangeBy) return true;
  if (state.statusFilter.length > 0) return true;
  if (state.assignedIds.length > 0 || state.createdByIds.length > 0) return true;
  if (state.mTypes.length > 0) {
    return true;
  }
  return false;
};

const getPrettyValue = (
  value: FieldValue,
  allMembersKeyed: Record<string, AssignedMember>,
  field:
    | (MdfField & {
        formId: string;
        settings: LayoutSettings;
      })
    | undefined,
  fieldId: string,
) => {
  switch (field?.type) {
    case FieldTypeEnum.date: {
      if (isDateRange(value)) {
        return `${isoToLocaleShort(value.startDate)} - ${isoToLocaleShort(value.endDate)}`;
      }
      break;
    }
    case FieldTypeEnum.multiplechoice: {
      if (Array.isArray(value)) {
        return value.join(', ');
      }
      break;
    }
    case FieldTypeEnum.treechoice: {
      if (Array.isArray(value)) {
        return value.join(' ▸ ');
      }
      break;
    }
    case FieldTypeEnum.relation:
      return 'relation';
    case FieldTypeEnum.user: {
      if (typeof value === 'string') {
        const member = allMembersKeyed[value];
        if (member) {
          return member;
        }
      }
    }
  }
  return `${fieldId}: ${value as string}`;
};

export const getChipLabel = (
  key: string,
  value: FieldValue,
  allMembersKeyed: Record<string, AssignedMember>,
  field:
    | (MdfField & {
        formId: string;
        settings: LayoutSettings;
      })
    | undefined,
) => {
  const fieldId = `${field?.settings.label ?? key}`;
  if (field?.type === FieldTypeEnum.relation) {
    return fieldId;
  }
  return getPrettyValue(value, allMembersKeyed, field, fieldId);
};

export const generateCSVFromMembers = (items: MemberType[], mdfs: Mdf[]): CsvKeyValue[] => {
  const fieldMap = getFieldMap(mdfs, 'default');
  const data: CsvKeyValue[] = [];

  items.forEach((item) => {
    const metadata: Metadata = JSON.parse(item?.metadata ?? '{}') as Metadata;
    const mdfId = item?.mdfId;

    const dataItem: CsvKeyValue = {
      Id: item.mId ?? '',
      Title: item.mTitle ?? '',
      Description: item.mDescription ?? '',
      Created: item.mCreatedAt ?? '',
      Updated: item.mUpdatedAt ?? '',
      'Created by': item.mCreatedById ?? '',
      'Assigned to': item.mAssignedMembers?.map((a) => a.mId).join(', ') ?? '',
      Status: item.mState ?? '',
    };
    if (!isEmpty(metadata) && mdfId) {
      Object.entries(metadata).forEach(([key, value]) => {
        const field = fieldMap[`${getFieldKey(key, mdfId)}`];
        const label = field?.settings.label ?? key;
        dataItem[label] = value?.toString();
      });
    }

    data.push(dataItem);
  });

  return data;
};

export const toolbarFilterDefaults: Omit<CommandToolbarProps, 'toolbarHeight'> = {
  sortBy: 'best',
  order: 'desc',
  mTypes: [],
  rangeBy: null,
  mdfId: null,
  defaultMdfId: null, // reserved for the default built in mdf ids
  statusFilter: [],
  assignedIds: [],
  createdByIds: [],
  platformTypes: [],
  isFiltering: false,
  semanticSearch: false,
  matchAllAssignees: false,
  showRestricted: false,
  restrictedItemsOnly: false,
};
