import React, { forwardRef, useRef, useState } from 'react';

import { Button, IconButton } from 'components/buttons';
import Scrollbar from 'components/scrollbar';
import Switch from 'components/switch';

import {
  Body,
  Filters,
  Footer,
  Header,
  HeaderTitle,
  Input,
  Root,
  SwitchControl,
  SwitchLabel,
  Toolbar,
} from './styled';

type Children = React.ReactNode;

interface DeckProps {
  children: Children;
  isDragging: boolean;
  dark?: boolean;
  minWidth?: string;
  maxWidth?: string;
  drawLeftBorder: boolean;
  isOver: boolean;
}
function Deck(
  {
    children,
    isDragging,
    dark = false,
    isOver,
    drawLeftBorder,
    minWidth = 'auto',
    maxWidth = '100%',
  }: Readonly<DeckProps>,
  ref?: React.Ref<HTMLDivElement>,
) {
  return (
    <Root
      $dark={dark}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
      $isDragging={isDragging}
      ref={ref}
      $drawLeftBorder={drawLeftBorder}
      $isOver={isOver}
    >
      {children}
    </Root>
  );
}

export const MainDeck = forwardRef(Deck);

Deck.Header = function DeckHeader({ children }: { children: Children }) {
  return <Header>{children}</Header>;
};

Deck.HeaderTitle = function DeckHeader({
  children,
  onDoubleClick,
}: {
  children: Children;
  onDoubleClick?: () => void;
}) {
  return <HeaderTitle onDoubleClick={onDoubleClick}>{children}</HeaderTitle>;
};

interface DeckHeaderInputProps {
  value?: string;
  autoFocus?: boolean;
  onBlur: (newVal: string) => void;
}

Deck.HeaderInput = function DeckHeaderInput({
  value,
  onBlur,
  autoFocus = false,
}: Readonly<DeckHeaderInputProps>) {
  const [val, setVal] = useState(value);
  const ref = useRef<HTMLInputElement | null>(null);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    e.target.select();
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && ref.current) {
      ref.current.blur();
    }
  };

  return (
    <Input
      ref={ref}
      name="header-input"
      value={val}
      autoFocus={autoFocus}
      onChange={(ev) => setVal(ev.target.value)}
      onKeyUp={handleKeyUp}
      onFocus={handleFocus}
      onBlur={() => onBlur(val ?? '')}
    />
  );
};

Deck.Toolbar = function DeckToolbar({ children }: { children: Children }) {
  return <Toolbar role="menubar">{children}</Toolbar>;
};

interface DeckButtonProps {
  children: React.ReactElement;
  onClick: () => void;
  label?: string;
  selected?: boolean;
  active?: boolean;
  title?: string;
  style?: React.CSSProperties;
}

Deck.Button = function DeckButton({
  children,
  onClick,
  label = '',
  selected = false,
  active = false,
  title = '',
  style = {},
}: Readonly<DeckButtonProps>) {
  const variant = active ? 'warning' : 'discreet';
  const usage = active ? 'warning' : 'text';

  return (
    <IconButton
      variant={selected ? 'contained' : variant}
      usage={selected ? 'cta' : usage}
      aria-label={label}
      onClick={onClick}
      height={32}
      width={32}
      title={title}
      style={style}
    >
      {children}
    </IconButton>
  );
};

interface DeckControlSwitchProps {
  children: React.ReactElement;
  selected?: boolean;
  onChange: (val: boolean) => void;
}

Deck.ControlSwitch = function DeckControlSwitch({
  onChange,
  selected,
  children,
}: Readonly<DeckControlSwitchProps>) {
  return (
    <SwitchControl>
      <Switch
        aria-label="Show my items only"
        onChange={onChange}
        selected={selected}
        disabled={false}
        onClick={undefined}
      />
      <SwitchLabel>{children}</SwitchLabel>
    </SwitchControl>
  );
};

interface DeckDropdownFilters {
  children: React.ReactElement;
  filtersOpen: boolean;
  setFiltersOpen: (val: boolean) => void;
  useOverflow?: boolean;
}

Deck.DropdownFilters = function DeckDropdownFilter({
  children,
  filtersOpen,
  setFiltersOpen,
  useOverflow = true,
}: Readonly<DeckDropdownFilters>) {
  const toggleShowFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  return (
    <Filters>
      {useOverflow ? (
        <Scrollbar>{filtersOpen && <>{children}</>}</Scrollbar>
      ) : (
        filtersOpen && <>{children}</>
      )}
      <Button height={32} style={{ flexShrink: 0 }} onClick={toggleShowFilters}>
        Close
      </Button>
    </Filters>
  );
};

interface DeckBody {
  children: React.ReactElement;
  paddingX?: number;
  paddingY?: number;
}

Deck.Body = function DeckBody({ children, paddingX = 0, paddingY = 0 }: Readonly<DeckBody>) {
  return (
    <Body $paddingX={paddingX} $paddingY={paddingY}>
      {children}
    </Body>
  );
};

Deck.Footer = function DeckFooter({ children }: { children: React.ReactElement }) {
  return <Footer>{children}</Footer>;
};

export default Deck;
