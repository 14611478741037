import { CSSProperties, forwardRef } from 'react';

import { StyledBox } from './styled';

export interface BoxProps {
  children: React.ReactNode;
  /** true: flex, false: block  */
  background?: string;
  container?: boolean;
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  flexGrow?: number;
  flexBasis?: string;
  flexShrink?: number;
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  flex?: string;
  tabIndex?: number;
  alignItems?:
    | 'stretch'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'baseline'
    | 'initial'
    | 'inherit'
    | 'normal'
    | 'start'
    | 'end';
  alignSelf?:
    | 'auto'
    | 'stretch'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'baseline'
    | 'initial'
    | 'inherit';
  margin?: string;
  padding?: string;
  border?: string;
  /** Examples: 300px, 30%, 10vh */
  width?: string;
  /** Examples: 300px, 30%, 10vh */
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  minWidth?: string;
  minHeight?: string;
  gap?: string | number;
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onMouseDown?: (ev: React.MouseEvent<HTMLElement>) => void;
  onMouseOver?: (ev: React.MouseEvent<HTMLElement>) => void;
  onMouseOut?: (ev: React.MouseEvent<HTMLElement>) => void;
  onDoubleClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  onKeyDown?: (ev: React.KeyboardEvent<HTMLElement>) => void;
  onContextMenu?: (ev: React.MouseEvent<HTMLElement>) => void;
  role?: string;
  cursor?:
    | 'auto'
    | 'default'
    | 'none'
    | 'context-menu'
    | 'help'
    | 'pointer'
    | 'progress'
    | 'wait'
    | 'cell'
    | 'crosshair'
    | 'text'
    | 'vertical-text'
    | 'alias'
    | 'copy'
    | 'move'
    | 'no-drop'
    | 'not-allowed'
    | 'grab'
    | 'grabbing'
    | 'all-scroll'
    | 'col-resize'
    | 'row-resize'
    | 'n-resize'
    | 'e-resize'
    | 's-resize'
    | 'w-resize'
    | 'ne-resize'
    | 'nw-resize'
    | 'se-resize'
    | 'sw-resize'
    | 'ew-resize'
    | 'ns-resize'
    | 'nesw-resize'
    | 'nwse-resize';
  className?: string;
  id?: string;
  overflow?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'inherit' | 'initial' | 'unset';
  title?: string;
  borderRadius?: string;
  position?: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky' | 'inherit' | 'initial';
  opacity?: string | number;
  style?: CSSProperties;
}

type StackProps = Omit<BoxProps, 'flexDirection'>;
type Ref = HTMLDivElement;

const Box = forwardRef<Ref, BoxProps>(({ children, ...rest }, ref) => (
  <StyledBox {...rest} ref={ref}>
    {children}
  </StyledBox>
));
Box.displayName = 'Box';

/** Children are stacked Horizontally */
const HStack = forwardRef<Ref, StackProps>(({ children, ...rest }, ref) => (
  <StyledBox {...rest} container flexDirection="row" ref={ref}>
    {children}
  </StyledBox>
));
HStack.displayName = 'HStack';

/** Children are stacked Vertically */
const VStack = forwardRef<Ref, StackProps>(({ children, ...rest }, ref) => (
  <StyledBox {...rest} container flexDirection="column" ref={ref}>
    {children}
  </StyledBox>
));
VStack.displayName = 'VStack';

/** Children are centered */
const Center = forwardRef<Ref, BoxProps>(({ children, ...rest }, ref) => (
  <StyledBox {...rest} container justifyContent="center" alignItems="center" ref={ref}>
    {children}
  </StyledBox>
));
Center.displayName = 'Center';

/** Flex container */
const Flex = forwardRef<Ref, BoxProps>(({ children, ...rest }, ref) => (
  <StyledBox {...rest} container ref={ref}>
    {children}
  </StyledBox>
));
Flex.displayName = 'Flex';

export { Box, Center, Flex, HStack, VStack };
