/* eslint-disable no-console */
import { useCallback, useEffect, useMemo } from 'react';

import { SearchParameters, useSearch } from 'api/search/useSearch';
import useOpenMember from 'components/contextMenu/useOpenMember';
import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';
import { CommandToolbarProps } from 'features/command/command-types';
import { toolbarFilterDefaults } from 'features/command/command-utils';
import { CommandList, CommandMounted } from 'lib/command';
import { useAllMembersKeyed } from 'store';
import { MemberType } from 'types/graphqlTypes';

import SearchResults from './views/search-results/SearchResults';

interface CommandMenuProps {
  storedSearchParams?: SearchParameters;
  selectedDate?: DateRange;
  previewItem?: (id: MemberType) => void;
  refreshCounter?: number;
  federatedSearchString?: string;
}

export function CommandResults({
  storedSearchParams,
  selectedDate,
  previewItem,
  refreshCounter,
  federatedSearchString,
}: Readonly<CommandMenuProps>) {
  const [allMembersKeyed] = useAllMembersKeyed();

  const { openItem } = useOpenMember();

  const handleOpenItem = useCallback(
    (member: MemberType) => {
      if (!previewItem) openItem(member);
      else previewItem(member);
    },
    [previewItem, openItem],
  );

  const toolbarState: CommandToolbarProps = useMemo(() => {
    const state = storedSearchParams?.toolbarState
      ? { ...storedSearchParams?.toolbarState }
      : {
          ...toolbarFilterDefaults,
        };
    if (selectedDate) {
      state.rangeBy = {
        scheduledAt: {
          from: selectedDate.startDate,
          to: selectedDate.endDate ?? selectedDate.startDate,
        },
      };
    }
    return state;
  }, [selectedDate, storedSearchParams]);

  const storedSearchString = storedSearchParams?.searchString ?? '';

  const { items, handleLoadMore, loading, refetch } = useSearch({
    toolbarState,
    skip: false,
    searchString: federatedSearchString
      ? `${storedSearchString} ${federatedSearchString}`
      : storedSearchString,
    metadataFilter: storedSearchParams?.metadataFilter,
    mdfId: storedSearchParams?.mdfId,
    perPagelimit: storedSearchParams?.perPagelimit,
    doPoll: true,
  });

  useEffect(() => {
    setTimeout(() => {
      void refetch();
    }, 500);
  }, [refreshCounter]);

  return (
    <CommandMounted>
      <CommandList>
        <SearchResults
          searchValue={storedSearchParams?.searchString ?? ''}
          sortedBy={toolbarState.sortBy}
          openItem={handleOpenItem}
          setOpen={() => {}}
          items={items}
          members={allMembersKeyed}
          loadMore={handleLoadMore}
          loading={loading}
        />
      </CommandList>
    </CommandMounted>
  );
}
