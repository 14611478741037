/* eslint-disable max-len */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Radio from 'components/buttons/radioButton';
import Checkbox from 'components/checkbox';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/dialogs/components/table/Table';
import Dialog from 'components/dialogs/DialogBuilder';
import { PopoverColors } from 'components/editMdfDialog/components/PopoverColors';
import Infobar from 'components/infobar';
import { useCreateSavedSearch } from 'components/savedSearch/useCreateSavedSearch';
import { useUpdateSavedSearch } from 'components/savedSearch/useUpdateSavedSearch';
import Text from 'components/text';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip';
import { Color, Emphasis, StyledTextField } from 'features/reusableStyled';
import { Box, HStack } from 'layouts/box/Box';
import type {
  SavedSearch,
  SavedSearchType,
  SearchFilterProps,
  VisiblePlaces,
} from 'types/graphqlTypes';

import type { GroupPolicy } from './SavedSearches';

import { CheckboxWrapper, ColorWrapper, StyledText } from './styled';

type VisibilityTypes = SavedSearchType | 'all';

interface SavedSearchDialogProps {
  openDialog: boolean;
  setOpenDialog: (val: boolean) => void;
  filters: SearchFilterProps | null;
  searchToUpdate?: SavedSearch;
  canAdministrate: boolean;
  groups: GroupPolicy[];
  currentLocation: VisiblePlaces | 'default';
}

function Tip() {
  return (
    <Text variant="caption" style={{ marginBottom: '1px' }}>
      Tip: Start with an emoji 🚀 to make it easy to find!{' '}
      <Tooltip
        title={
          <span>
            Bring up the emoji keyboard by pressing <Emphasis>Ctrl+Cmd+Space</Emphasis> on a Mac, or{' '}
            <Emphasis>Windows logo key + . (period)</Emphasis> for Windows.
          </span>
        }
      >
        <span style={{ textDecoration: 'underline' }}>How?</span>
      </Tooltip>
    </Text>
  );
}

const getVisibleTo = (type: VisibilityTypes, groups?: string[]) => {
  if (type === 'all' || type === 'private') return [];
  return groups ?? [];
};

const getVisibleIn = (visibleIn: VisiblePlaces[] | undefined) => {
  if (visibleIn === undefined || visibleIn.length === 0) return;
  return visibleIn;
};

const getTypeFromVisibleTo = (
  type: SavedSearchType | undefined,
  visibleTo: string[] | undefined,
): VisibilityTypes => {
  if (type === 'private' || type === undefined) return 'private';
  return visibleTo?.length ? 'shared' : 'all';
};

interface SavedSearchLocation {
  label: string;
  type: VisiblePlaces;
}

const locations: SavedSearchLocation[] = [
  {
    label: 'Contacts sidebar',
    type: 'contacts_sidebar',
  },
  {
    label: 'Left sidebar',
    type: 'left_sidebar',
  },
];

export default function SavedSearchDialog({
  filters,
  openDialog,
  setOpenDialog,
  searchToUpdate,
  canAdministrate,
  groups,
  currentLocation,
}: Readonly<SavedSearchDialogProps>) {
  const { errorToast } = useToast();
  const { createSavedSearch } = useCreateSavedSearch(
    currentLocation === 'default' ? undefined : currentLocation,
  );
  const { updateSavedSearch } = useUpdateSavedSearch(
    currentLocation === 'default' ? undefined : currentLocation,
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const [type, setType] = useState<VisibilityTypes>('private');
  const [label, setLabel] = useState('New saved search');
  const [color, setColor] = useState<string>();
  const [visibleIn, setVisibleIn] = useState<VisiblePlaces[] | undefined>(
    currentLocation !== 'default' ? [currentLocation] : undefined,
  );
  const [userGroups, setUserGroups] = useState<string[] | undefined>();
  const [savedSearchToUpdate, setSavedSearchToUpdate] = useState(searchToUpdate);
  const [doUpdateFilters, setDoUpdateFilters] = useState(false);

  useEffect(() => {
    if (searchToUpdate !== savedSearchToUpdate) {
      setSavedSearchToUpdate(searchToUpdate);
    }
    setLabel(searchToUpdate?.label ?? 'New saved search');
    setType(getTypeFromVisibleTo(searchToUpdate?.type, searchToUpdate?.visibleTo));
    setUserGroups(searchToUpdate?.visibleTo);
    setColor(searchToUpdate?.color);
    if (searchToUpdate) {
      setVisibleIn(searchToUpdate.visibleIn ?? []);
    } else if (currentLocation !== 'default') {
      setVisibleIn([currentLocation]);
    }
  }, [searchToUpdate]);

  const onUpdate = useCallback(() => {
    if (searchToUpdate) {
      updateSavedSearch({
        ...searchToUpdate,
        filters: doUpdateFilters ? { ...filters } : searchToUpdate.filters,
        type: type === 'all' ? 'shared' : type,
        label,
        color,
        visibleTo: getVisibleTo(type, userGroups),
        visibleIn: getVisibleIn(visibleIn) ?? [],
      }).catch(errorToast);
      setOpenDialog(false);
    }
  }, [
    setOpenDialog,
    filters,
    type,
    label,
    color,
    userGroups,
    doUpdateFilters,
    visibleIn,
    updateSavedSearch,
    searchToUpdate,
  ]);

  const onSaveNew = useCallback(() => {
    if (filters) {
      createSavedSearch({
        filters: JSON.stringify(filters),
        label,
        color,
        type: type === 'all' ? 'shared' : type,
        visibleTo: getVisibleTo(type, userGroups),
        visibleIn: getVisibleIn(visibleIn),
      }).catch(errorToast);
      setOpenDialog(false);
    }
  }, [createSavedSearch, setOpenDialog, filters, type, label, color, userGroups, visibleIn]);

  useEffect(() => {
    if (openDialog && inputRef.current) {
      setTimeout(() => {
        inputRef.current!.focus();
      }, 10);
    }
    return () => {
      setDoUpdateFilters(false);
    };
  }, [openDialog]);

  const onGroupChange = useCallback(
    (group: GroupPolicy) => {
      if (userGroups?.includes(group.id)) {
        setUserGroups((userGroups ?? []).filter((g) => g !== group.id));
      } else {
        setUserGroups((prevValue) => {
          return [...(prevValue ?? []), group.id];
        });
      }
    },
    [userGroups, setUserGroups],
  );

  const disabledTooltip = useMemo(() => {
    if (type === 'private' || type === 'all') return '';
    if (userGroups === undefined || userGroups.length === 0) return '';
    if (userGroups.length === 0) return 'At least one group must be chosen';
    return '';
  }, [userGroups, type, visibleIn]);

  const doConfirm = useCallback(() => {
    if (searchToUpdate) {
      onUpdate();
    } else {
      onSaveNew();
    }
  }, [searchToUpdate, onUpdate, onSaveNew]);

  const onVisibilityChange = useCallback(
    (newPlace: VisiblePlaces) => {
      setVisibleIn((prevValue) => {
        const newValues = prevValue ?? [];
        if (newValues.includes(newPlace)) {
          return [...newValues.filter((v) => v !== newPlace)];
        } else {
          return [...newValues, newPlace];
        }
      });
    },
    [setVisibleIn],
  );

  const onTypeChange = useCallback(
    (newType: VisibilityTypes) => {
      if (newType === 'private') {
        setType('private');
        setUserGroups([]);
      }

      if (canAdministrate) {
        if (newType === 'all') {
          setUserGroups([]);
        } else {
          setUserGroups(groups.map((g) => g.id));
        }
        setType(newType);
      }
    },
    [canAdministrate, setType],
  );

  const confirmButtonDisabled = label.length === 0 || disabledTooltip.length > 0;

  const onKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (ev.key === 'Enter') {
        // We stop propagation regardless, enter will otherwise potentially trigger
        // an open event on the selected search item behind this dialog if
        // dina command bar is up
        ev.preventDefault();
        ev.stopPropagation();
        if (!confirmButtonDisabled) doConfirm();
      }
    },
    [label, disabledTooltip],
  );

  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)} style={{ width: '400px' }}>
      <Dialog.Header>
        {savedSearchToUpdate ? 'Update saved search' : 'Create saved search'}
      </Dialog.Header>
      <Dialog.Body>
        <Dialog.Group noMargin>
          <Dialog.Label>Label</Dialog.Label>
          <Box width="100%" padding="0 0 0 4px">
            <StyledTextField
              ref={inputRef}
              fullWidth
              autoFocus
              variant="filled"
              value={label}
              onFocus={(ev) => ev.currentTarget.select()}
              onChange={(e) => setLabel(e.currentTarget.value)}
              inputProps={{
                onKeyDown: (ev) => onKeyDown(ev),
              }}
              helperText={<Tip />}
            />
          </Box>
        </Dialog.Group>
        <Dialog.Group>
          <Dialog.Label>Visible to</Dialog.Label>
          <HStack onClick={() => onTypeChange('private')} margin="0 0 4px 10px" gap="6px">
            <Radio selected={type === 'private'} size={20} />
            <StyledText variant="listItemLabel" style={{}}>
              Me
            </StyledText>
          </HStack>
          <Tooltip
            title={!canAdministrate ? 'You do not have permission to manage shared searches' : ''}
          >
            <HStack onClick={() => onTypeChange('all')} margin="0 0 4px 10px" gap="6px">
              <Radio selected={type === 'all'} size={20} disabled={!canAdministrate} />
              <StyledText variant="listItemLabel" color={!canAdministrate ? 'disabled' : undefined}>
                Everyone
              </StyledText>
            </HStack>
          </Tooltip>
          <Tooltip
            title={!canAdministrate ? 'You do not have permission to manage shared searches' : ''}
          >
            <HStack onClick={() => onTypeChange('shared')} margin="0 0 8px 10px" gap="6px">
              <Radio selected={type === 'shared'} size={20} disabled={!canAdministrate} />
              <StyledText variant="listItemLabel" color={!canAdministrate ? 'disabled' : undefined}>
                Specific groups
              </StyledText>
            </HStack>
          </Tooltip>
          {type === 'shared' && (
            <>
              <Box maxHeight="40vh" width="100%" padding="0 0 8px 0" overflow="auto">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Group</TableHead>
                      <TableHead>Shared with</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {groups.map((p) => (
                      <TableRow key={p.id}>
                        <TableCell>
                          <StyledText variant="listItemLabel" onClick={() => onGroupChange(p)}>
                            {p.label}
                          </StyledText>
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            selected={userGroups?.length === 0 || userGroups?.includes(p.id)}
                            onClick={() => onGroupChange(p)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              {canAdministrate && (
                <Infobar>
                  Due to having permission to edit shared searches, you will always see all shared
                  searches in the Dina command bar by default.
                </Infobar>
              )}
            </>
          )}
        </Dialog.Group>
        <Dialog.Group>
          <Dialog.Label>Also visible in</Dialog.Label>
          <Box maxHeight="50vh" width="100%" padding="0 0 8px 0" overflow="auto">
            {locations.map((p) => (
              <HStack key={p.type} justifyContent="start" height="24px">
                <Checkbox
                  selected={visibleIn?.includes(p.type)}
                  onClick={() => onVisibilityChange(p.type)}
                />
                <div>
                  <StyledText variant="listItemLabel" onClick={() => onVisibilityChange(p.type)}>
                    {p.label}
                  </StyledText>
                </div>
              </HStack>
            ))}
          </Box>
        </Dialog.Group>
        <Dialog.Group>
          <Dialog.Label>Look &amp; feel</Dialog.Label>
          <PopoverColors
            onColorChoice={(c: string) => setColor(c)}
            onClearColor={() => setColor(undefined)}
            selectedColor={color}
          >
            <ColorWrapper padding="6px 0 6px 6px" gap="6px">
              <Color $color={color} $size={16} />
              <Text variant="listItemLabel" className="chckbox-label">
                Set a color
              </Text>
            </ColorWrapper>
          </PopoverColors>
          {savedSearchToUpdate && (
            <Dialog.Group>
              <Dialog.Label>Filters</Dialog.Label>
              <CheckboxWrapper>
                <Checkbox
                  selected={doUpdateFilters}
                  onClick={() => setDoUpdateFilters(!doUpdateFilters)}
                />
                <Text
                  variant="listItemLabel"
                  className="chckbox-label"
                  onClick={() => setDoUpdateFilters(!doUpdateFilters)}
                >
                  Update to current search filters?
                </Text>
              </CheckboxWrapper>
            </Dialog.Group>
          )}
        </Dialog.Group>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          label={savedSearchToUpdate ? 'Update' : 'Save'}
          title={label.length === 0 ? 'Label required' : disabledTooltip}
          onConfirm={doConfirm}
          disabled={confirmButtonDisabled}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
