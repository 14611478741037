import { useCallback, useEffect, useMemo, useState } from 'react';

import { SearchParameters } from 'api/search/useSearch';
import CommandMenu from 'components/command/Command';
import { CheckboxWithLabel } from 'components/createNewV3/CreateNew';
import Dialog from 'components/dialogs/DialogBuilder';
import { MAX_RELATIONS } from 'components/mdfEditor/fields/relation/relation-utils';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { toSearchFilterProps, toSearchParameters } from 'features/command/command-utils';
import { StyledTextField } from 'features/reusableStyled';
import { Box, VStack } from 'layouts/box/Box';
import { FieldTypeEnum, MdfField, SearchFilterProps } from 'types/graphqlTypes';

import { FieldHeader } from './EditFieldModel';

interface Props {
  onConfirm: (field: MdfField) => void;
  fieldToConfig: MdfField | null;
  open: boolean;
  setOpen: (val: boolean) => void;
}

export const supportsAdditionalConfig = (field: MdfField) =>
  [FieldTypeEnum.multiplechoice, FieldTypeEnum.user, FieldTypeEnum.relation].includes(field.type);

const defaultModel: MdfField = {
  fieldId: 'someId',
  type: FieldTypeEnum.text,
  defaultValue: { value: null },
};

type Member = 'user' | 'department' | 'team';
type Contact = 'contact';

type RelationConstraint = { max?: number };

const sanitizeRelationFieldNumber = (val: number) => {
  if (val < 1) return 1;
  if (val > MAX_RELATIONS) return MAX_RELATIONS;
  return val;
};

export function ConfigFieldDialog({ onConfirm, open, setOpen, fieldToConfig }: Readonly<Props>) {
  const [field, setField] = useState<MdfField>(defaultModel);

  const shouldShow = useMemo(() => {
    return fieldToConfig ? supportsAdditionalConfig(fieldToConfig) : false;
  }, [fieldToConfig]);

  useEffect(() => {
    setField(fieldToConfig ? { ...fieldToConfig } : defaultModel);
  }, [fieldToConfig]);

  const toggleMember = useCallback(
    (val: Member | Contact) => {
      let filter = field.filter ?? [];
      if (val === 'contact') {
        filter = filter.includes(val) ? [] : [val];
      } else {
        if (filter.includes('contact')) {
          filter = [];
        }
        if (filter.includes(val)) {
          filter = [...filter.filter((f) => f !== val)];
        } else {
          filter = [...filter, val];
        }
      }
      setField({ ...field, filter });
    },
    [field, setField],
  );

  const onFilterConfig = (val: SearchParameters) => {
    setField({
      ...field,
      filters: toSearchFilterProps(val.toolbarState, val.searchString, val.metadataFilter ?? {}),
    });
  };

  const parsedParams = useMemo(() => {
    return toSearchParameters(field.filters ?? ({} as SearchFilterProps));
  }, [field.filters]);

  const setConstraint = useCallback(
    (val: Record<string, unknown>) => {
      setField((prev) => {
        return {
          ...prev,
          constraint: val,
        };
      });
    },
    [setField],
  );

  const handleSetRelationConstraint = useCallback(
    (val: string) => {
      const number = Number(val);
      if (typeof number === 'number') {
        setConstraint({ max: sanitizeRelationFieldNumber(number) });
      }
    },
    [setConstraint],
  );

  const AdditionalConfig = useMemo(() => {
    switch (field.type) {
      case FieldTypeEnum.multiplechoice: {
        return (
          <Tooltip title="Allow users to add values that are not present in the model">
            <span>
              <CheckboxWithLabel
                selected={field.freeform ?? false}
                disabled={undefined}
                onClick={() => {
                  setField({ ...field, freeform: !field.freeform });
                }}
                label="Freeform"
              />
            </span>
          </Tooltip>
        );
      }
      case FieldTypeEnum.user: {
        return (
          <VStack width="100%" alignItems="flex-start">
            <Text variant="listItemLabelBold">Internals</Text>
            <VStack width="100%" alignItems="flex-start" padding="8px 8px 12px">
              <CheckboxWithLabel
                selected={field.filter?.includes('user') ?? false}
                onClick={() => toggleMember('user')}
                label="Users"
              />
              <CheckboxWithLabel
                selected={field.filter?.includes('team') ?? false}
                onClick={() => toggleMember('team')}
                label="Teams"
              />
              <CheckboxWithLabel
                selected={field.filter?.includes('department') ?? false}
                onClick={() => toggleMember('department')}
                label="Departments"
              />
            </VStack>
            <Text variant="listItemLabelBold">Externals</Text>
            <VStack width="100%" alignItems="flex-start" padding="8px">
              <CheckboxWithLabel
                selected={field.filter?.includes('contact') ?? false}
                onClick={() => toggleMember('contact')}
                label="Contacts"
              />
            </VStack>
          </VStack>
        );
      }
      case FieldTypeEnum.relation: {
        const constraint = (field.constraint ?? {}) as RelationConstraint;
        return (
          <Box
            container
            flexDirection="column"
            alignItems="start"
            height="70vh"
            width="100%"
            gap="4px"
          >
            <FieldHeader>Maximum amount of relations users can set</FieldHeader>
            <StyledTextField
              variant="filled"
              type="number"
              value={constraint?.max}
              onChange={(ev) => handleSetRelationConstraint(ev.currentTarget.value)}
            />
            <FieldHeader>Configure default filter for search field</FieldHeader>
            <CommandMenu
              location="default"
              isMounted
              allowStaticSearchOptions={false}
              setSearchParams={onFilterConfig}
              searchParams={parsedParams}
            />
          </Box>
        );
      }
      default:
        return null;
    }
  }, [field, setField, onFilterConfig, toggleMember, parsedParams, handleSetRelationConstraint]);

  const doConfirm = () => {
    onConfirm(field);
    setField(defaultModel);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} style={{ width: '800px' }}>
      <Dialog.Header>Additional field options</Dialog.Header>
      <Dialog.Body>{shouldShow && AdditionalConfig}</Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton label="Confirm" onConfirm={doConfirm} />
      </Dialog.Footer>
    </Dialog>
  );
}
