import { ComponentPropsWithoutRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop, XYCoord } from 'react-dnd';
import styled from '@emotion/styled/macro';

import { ReactComponent as Delete } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as FiltersOff } from 'assets/icons/systemicons/filters_off.svg';
import { ReactComponent as FiltersOn } from 'assets/icons/systemicons/filters_on.svg';
import { ReactComponent as Ellipsis } from 'assets/icons/systemicons/more_vertical.svg';
import { ReactComponent as Print } from 'assets/icons/systemicons/print.svg';
import { IconButton } from 'components/buttons';
import Deck, { useSelectIcon } from 'components/deck';
import Collapsed from 'components/deck/components/collapsed';
import { MainDeck } from 'components/deck/Deck';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import Popover from 'components/dialogs/PopoverBuilder';
import Tooltip from 'components/tooltip';
import { TooltipPlacement } from 'components/tooltip/Tooltip';
import { SpaceViewLayout } from 'features/layouts/layouts';
import { Box } from 'layouts/box/Box';
import { minWidthByType } from 'screens/space/components/widgets/utils';
import {
  useDeleteWidget,
  useMoveWidget,
  useNewWidgetId,
  useUpdateWidgetFilters,
  useUpdateWidgetProviders,
  useUpdateWidgetTitle,
} from 'screens/space/store/widgets';
import { PolymorphicComponentProps } from 'types';
import { FilterValueType } from 'types/widget';
import { getSessionStorage, setSessionStorage } from 'utils/storage/sessionStorage';

import { WIDGETS } from '../constants';

export type FilterProps = {
  filters: FilterValueType;
  updateFilters?: (filters: FilterValueType) => void;
};

export type FilterComponentType = <C extends React.ElementType>(
  props: PolymorphicComponentProps<C, FilterProps>,
) => React.ReactElement | null;

interface WidgetCardProps {
  layout: SpaceViewLayout;
  useOverflow?: boolean;
  headerProps?: React.ReactNode;
  children: React.ReactNode;
  customWidth?: number;
  activeFilters?: boolean;
  spaceId?: string;
  filters?: FilterValueType;
  providers?: { mRefId: string }[];
  allProviders?: string[];
  filterComponent?: FilterComponentType;
  title: string;
  mRefId: string;
  mId: string;
  writeAccess?: boolean;
  type: WIDGETS;
  onPrint?: () => void;
}

interface HeaderMenuProps {
  filtersOpen: boolean;
  setFiltersOpen: (open: boolean) => void;
  headerProps?: React.ReactNode;
  hasFilters: boolean;
  activeFilters?: boolean;
}

const HeaderButtons = ({
  filtersOpen,
  setFiltersOpen,
  headerProps,
  hasFilters,
  activeFilters = false,
}: HeaderMenuProps) => {
  const toggleFilters = useCallback(() => {
    setFiltersOpen(!filtersOpen);
  }, [filtersOpen]);

  return (
    <Box container>
      {headerProps}
      {hasFilters && (
        <Deck.Button
          onClick={toggleFilters}
          selected={filtersOpen}
          title="Filters"
          active={activeFilters}
        >
          {filtersOpen ? <FiltersOn /> : <FiltersOff />}
        </Deck.Button>
      )}
    </Box>
  );
};

const StyledHeader = styled('div')`
  cursor: grab;
  ${({ theme }) => theme.typography.dina.h6};
  height: 48px;
  display: flex;
  align-items: center;
  margin-left: 4px;
  flex: 1 1 auto;
`;

export const ListItem = styled('li')`
  user-select: none;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  border-radius: 8px;
  padding: 0 8px;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
    filter: brightness(108%);
  }
`;

const getDirection = (coords: XYCoord | null): 'right' | 'left' => {
  if (coords === null) return 'left';
  return coords.x > 0 ? 'right' : 'left';
};

const FilterMenu = <T extends React.ElementType>(
  props: {
    as?: T;
    mId: string;
    setWidthPreview?: (width: string) => void;
  } & ComponentPropsWithoutRef<React.ElementType extends T ? 'span' : T>,
) => {
  const { as: Comp = 'span', mId, setWidthPreview, ...rest } = props;
  const updateWidgetFilters = useUpdateWidgetFilters();
  const updateWidgetProviders = useUpdateWidgetProviders();

  const handleUpdateFilters = useCallback((newFilters: FilterValueType) => {
    updateWidgetFilters({ id: mId, filters: newFilters });
  }, []);

  const handleUpdateProviders = useCallback(
    (newProviders: { mRefId: string }[]) => {
      updateWidgetProviders({ id: mId, providers: newProviders, type: WIDGETS.FEED });
    },
    [updateWidgetProviders],
  );

  return (
    <Comp
      {...rest}
      setWidthPreview={setWidthPreview}
      updateFilters={handleUpdateFilters}
      updateProviders={handleUpdateProviders}
    />
  );
};

function WidgetWrapper({
  layout,
  headerProps = undefined,
  children,
  filterComponent = undefined,
  filters = undefined,
  providers = undefined,
  allProviders = undefined,
  customWidth = undefined,
  activeFilters,
  spaceId,
  title,
  mId,
  mRefId,
  writeAccess,
  type,
  onPrint,
  useOverflow = true,
}: Readonly<WidgetCardProps>) {
  const moveWidget = useMoveWidget();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>((getSessionStorage(mId) as boolean) ?? false);
  const [deckWidth, setDeckWidth] = useState<string | undefined>(
    customWidth ? `${customWidth}px` : undefined,
  );

  const itemRef = useRef<HTMLSpanElement>(null);

  const { Icon, setIsHovered } = useSelectIcon(type);
  const deleteWidget = useDeleteWidget();
  const updateTitle = useUpdateWidgetTitle();
  const [newWidgetId, setNewWidgetId] = useNewWidgetId();
  const [editing, setEditing] = useState(false);

  const handleUpdateTitle = useCallback(
    (newTitle: string) => {
      updateTitle({ id: mId, title: newTitle });
      setEditing(false);
    },
    [setEditing, updateTitle, mId],
  );

  const handleUpdateCollapsed = useCallback(
    (val: boolean) => {
      if (layout === 'horizontal') {
        setCollapsed(val);
        setSessionStorage(mId, val);
      }
    },
    [mId, layout],
  );

  const handleDelete = () => setDeleteDialogOpen(true);
  const onCloseDelete = () => setDeleteDialogOpen(false);

  const onConfirmDelete = useCallback(() => {
    onCloseDelete();
    deleteWidget({ id: mId, layout });
  }, [layout]);

  useEffect(() => {
    if (newWidgetId === mId && !editing) {
      setEditing(true);

      // this should only ever fire once.
      setNewWidgetId(null);
    }
  }, [newWidgetId, mId, setEditing]);

  const hasFilters = Boolean(filterComponent);
  const showHeaderMenu = Boolean(headerProps ?? hasFilters);

  const [{ isDragging }, drag] = useDrag({
    type: 'WIDGET_DRAG',
    item: { id: mRefId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver, initialOffset, overId, canDrop }, drop] = useDrop({
    accept: ['WIDGET_DRAG'],
    canDrop: () => layout === 'horizontal',
    drop(p: { id: string }) {
      if (p.id !== mRefId) {
        moveWidget({ id: p.id, targetIdOrIndex: mRefId, replace: false });
      }
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
      initialOffset: monitor.getDifferenceFromInitialOffset(),
      overId: monitor.getItem<{ id: string }>(),
    }),
  });
  const direction = getDirection(initialOffset);

  return (
    <>
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={onCloseDelete}
        onClick={onConfirmDelete}
        title="Delete Widget"
        message={`Are you sure you want to delete widget '${title}'? This cannot be undone!`}
      />
      {collapsed ? (
        <Collapsed
          ref={(ref) => {
            drag(ref);
            drop(ref);
          }}
          isDragging={isDragging}
          isOver={isOver && canDrop && overId?.id !== mRefId}
          drawLeftBorder={direction === 'left'}
          title={title}
          type={type}
          onClick={() => handleUpdateCollapsed(false)}
          providers={providers}
        />
      ) : (
        <MainDeck
          ref={(ref) => drop(ref)}
          isOver={isOver && canDrop && overId?.id !== mRefId}
          drawLeftBorder={direction === 'left'}
          minWidth={deckWidth ?? minWidthByType[type]}
          maxWidth={deckWidth ?? '100%'}
          isDragging={isDragging}
        >
          <Deck.Header>
            <Deck.Button
              onClick={() => handleUpdateCollapsed(true)}
              title={layout === 'horizontal' ? 'Collapse' : undefined}
            >
              <Icon
                onMouseEnter={() => {
                  if (layout === 'horizontal') {
                    setIsHovered(true);
                  }
                }}
                onMouseLeave={() => {
                  if (layout === 'horizontal') {
                    setIsHovered(false);
                  }
                }}
              />
            </Deck.Button>

            {writeAccess ? (
              <StyledHeader
                ref={(r) => {
                  if (!editing) {
                    drag(r);
                  }
                }}
              >
                {editing ? (
                  <Deck.HeaderInput
                    onBlur={(val) => {
                      handleUpdateTitle(val);
                    }}
                    autoFocus
                    value={title}
                  />
                ) : (
                  <Tooltip
                    title="Double click to edit or drag to reorder"
                    placement={TooltipPlacement.TOP}
                    enterDelay={500}
                  >
                    <span>
                      <Deck.HeaderTitle onDoubleClick={() => setEditing(true)}>
                        {title}
                      </Deck.HeaderTitle>
                    </span>
                  </Tooltip>
                )}
              </StyledHeader>
            ) : (
              <Deck.HeaderTitle>{title}</Deck.HeaderTitle>
            )}
            {writeAccess && (
              <>
                {showHeaderMenu && (
                  <HeaderButtons
                    filtersOpen={filtersOpen}
                    setFiltersOpen={setFiltersOpen}
                    hasFilters={hasFilters}
                    headerProps={headerProps}
                    activeFilters={activeFilters}
                  />
                )}
                <span ref={itemRef}>
                  <Popover>
                    <Popover.Trigger asChild>
                      <div>
                        <IconButton
                          width={32}
                          height={32}
                          title="More"
                          variant="discreet"
                          usage="text"
                          iconHeight={16}
                        >
                          <Ellipsis />
                        </IconButton>
                      </div>
                    </Popover.Trigger>
                    <Popover.Content style={{ padding: 0 }}>
                      <ListItem onClick={handleDelete}>
                        <Delete />
                        Delete Widget
                      </ListItem>
                      {onPrint && (
                        <ListItem onClick={onPrint}>
                          <Print />
                          Print Note
                        </ListItem>
                      )}
                    </Popover.Content>
                  </Popover>
                </span>
              </>
            )}
          </Deck.Header>
          <Deck.Body>
            {filtersOpen ? (
              <Deck.DropdownFilters
                useOverflow={useOverflow}
                filtersOpen={filtersOpen}
                setFiltersOpen={setFiltersOpen}
              >
                <FilterMenu
                  as={filterComponent}
                  filters={filters as FilterValueType}
                  providers={allProviders}
                  selectedProviders={providers}
                  mId={mId}
                  spaceId={spaceId}
                  setWidthPreview={setDeckWidth}
                />
              </Deck.DropdownFilters>
            ) : (
              <>{children}</>
            )}
          </Deck.Body>
        </MainDeck>
      )}
    </>
  );
}

export default memo(WidgetWrapper);
