/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ItemParams, useContextMenu } from 'react-contexify';
import { keyBy } from 'lodash';

import { useUpdateOrdering } from 'api/ordering/useUpdateOrdering';
import useGetGroupPolicy from 'api/useGetGroupPolicy';
import { ReactComponent as Add } from 'assets/icons/systemicons/add.svg';
import { IconButton } from 'components/buttons';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { useDeleteSavedSearch } from 'components/savedSearch/useDeleteSavedSearch';
import {
  locationToOrderingMap,
  useGetSavedSearches,
} from 'components/savedSearch/useGetSavedSearch';
import SortableList, {
  getOrderFromSortableItems,
  RenderItemProps,
  SortableItems,
} from 'components/sortableList';
import useToast from 'components/toast/useToast';
import { CommandToolbarProps } from 'features/command/command-types';
import { toSearchFilterProps } from 'features/command/command-utils';
import ContextMenu from 'features/contextMenu/ContextMenu';
import { SAVED_SEARCH_MENU_ID } from 'features/sidepanel/ComponentUtils';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useMonitorSize from 'hooks/useMonitorSize';
import { Box, VStack } from 'layouts/box/Box';
import {
  MemberType,
  Metadata,
  SavedSearch,
  SearchFilterProps,
  VisiblePlaces,
} from 'types/graphqlTypes';

import { CommandHeader } from '../styled';

import SavedSearchDialog from './SavedSearchDialog';
import SavedSearchItem from './SavedSearchItem';

import { StyledHeaderText } from './styled';

// TODO - useGetGroupPolicy should have this typed internally instead of MemberType.
export interface GroupPolicy {
  id: string;
  label: string;
}

const toTypedGroupPolicy = (groups: MemberType[] | undefined): GroupPolicy[] => {
  return (groups ?? []).map((g) => {
    return {
      id: g.mRefId!,
      label: g.mTitle!,
    };
  });
};

interface SavedSearchesProps {
  toolbarState: CommandToolbarProps;
  searchString: string;
  metaDataFilter: Metadata;
  selectedSavedSearch: string | null;
  onApplySavedSearch: (s: SavedSearch) => void;
  openDialog: boolean;
  setOpenDialog: (val: boolean) => void;
  location: VisiblePlaces | 'default';
}

interface ContextMenuProps {
  item: SavedSearch;
}

const breakPoints = {
  small: 110,
  medium: 200,
};

export default function SavedSearches({
  toolbarState,
  searchString,
  selectedSavedSearch,
  metaDataFilter,
  openDialog,
  setOpenDialog,
  onApplySavedSearch,
  location,
}: Readonly<SavedSearchesProps>) {
  const [checkUserRight] = useCheckUserRight();
  const { groupPolicies } = useGetGroupPolicy();
  const { errorToast } = useToast();
  const { searches, loading } = useGetSavedSearches(location === 'default' ? undefined : location);
  const { deleteSavedSearch } = useDeleteSavedSearch();
  const { updateOrdering } = useUpdateOrdering(locationToOrderingMap[location]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [itemToDelete, setItemToDelete] = useState<SavedSearch | null>(null);
  const [itemToUpdate, setItemToUpdate] = useState<SavedSearch | null>(null);
  const { show } = useContextMenu({ id: `${SAVED_SEARCH_MENU_ID}##${location}` });
  const { ref, size } = useMonitorSize<HTMLDivElement>(breakPoints);

  const groups = useMemo(() => {
    return toTypedGroupPolicy(groupPolicies);
  }, [groupPolicies]);

  const groupMap = useMemo(() => {
    return keyBy(groups, (g) => g.id);
  }, [groups]);

  const map = useMemo(() => {
    return keyBy(searches, (s) => s.id);
  }, [searches]);

  const canAdministrate = useMemo(() => {
    return checkUserRight('search', 'saved-search-administrator');
  }, [checkUserRight]);

  const filters: SearchFilterProps | null = useMemo(() => {
    // no need to compute this on every change if the dialog is not up.
    if (!openDialog) return null;
    return toSearchFilterProps(toolbarState, searchString, metaDataFilter);
  }, [toolbarState, searchString, metaDataFilter, openDialog]);

  const onKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLElement>, s: SavedSearch) => {
      if (ev.key === 'Enter' || ev.key === 'Space') {
        ev.preventDefault();
        ev.stopPropagation();
        onApplySavedSearch(s);
      }
    },
    [onApplySavedSearch],
  );

  const onContextMenu = useCallback(
    (ev: React.MouseEvent<HTMLElement>, s: SavedSearch) => {
      ev.stopPropagation();
      ev.preventDefault();

      if (s.type === 'shared' && !canAdministrate) return;

      show({
        event: ev,
        props: {
          item: s,
        },
      });
    },
    [show, canAdministrate],
  );

  const handleMenuItemClicked = useCallback(
    (data: ItemParams<ContextMenuProps>) => {
      if (!data.props) return;
      if (data.id === 'delete') {
        setItemToDelete(data.props.item);
      } else if (data.id === 'update') {
        setItemToUpdate(data.props.item);
        setOpenDialog(true);
      }
    },
    [setItemToDelete, setItemToUpdate],
  );

  const handleDelete = useCallback(() => {
    if (itemToDelete) {
      deleteSavedSearch(itemToDelete.id, itemToDelete.type).catch(errorToast);
      setItemToDelete(null);
    }
  }, [itemToDelete, setItemToDelete]);

  useEffect(() => {
    if (openDialog && inputRef.current) {
      setTimeout(() => {
        inputRef.current!.focus();
      }, 10);
    }
  }, [setOpenDialog]);

  const onCreateNew = useCallback(() => {
    setItemToUpdate(null);
    setOpenDialog(true);
  }, [setItemToUpdate, setOpenDialog]);

  const updateOrder = useCallback(
    (newOrder: SortableItems) => {
      const order = getOrderFromSortableItems(newOrder);
      updateOrdering(order).catch(errorToast);
    },
    [updateOrdering],
  );

  const renderSavedSearchItem = useCallback(
    (renderItemProps: RenderItemProps) => {
      const item = map[renderItemProps.id];
      if (!item) return null;
      return (
        <SavedSearchItem
          key={renderItemProps.id}
          item={item}
          parentSize={size}
          onClick={() => onApplySavedSearch(item)}
          selected={selectedSavedSearch === item.id}
          onKeyDown={(ev) => onKeyDown(ev, item)}
          onContextMenu={(ev) => onContextMenu(ev, item)}
          groupMap={groupMap}
          {...renderItemProps}
        />
      );
    },
    [selectedSavedSearch, map, groupMap, size, onContextMenu, onKeyDown, onApplySavedSearch],
  );

  return (
    <>
      <CommandHeader justifyContent="space-between">
        <StyledHeaderText variant="caption" $small={size === 'small'}>
          {size === 'small' ? 'Searches' : 'Saved searches'}
        </StyledHeaderText>
        <IconButton
          title="Save current search (ctrl / cmd + s)"
          size={24}
          iconSize={20}
          usage="text"
          tabIndex={-1}
          onClick={onCreateNew}
          style={{ flexShrink: 0 }}
        >
          <Add />
        </IconButton>
      </CommandHeader>

      {searches.length === 0 && loading ? (
        <Box width="100%" height="100%" position="relative">
          <LoadingIndicator />
        </Box>
      ) : (
        <>
          <VStack
            width="100%"
            overflow="auto"
            justifyContent="start"
            height="calc(100% - 32px)"
            ref={ref}
          >
            <SortableList
              order={searches}
              updateOrder={updateOrder}
              direction="vertical"
              renderItem={renderSavedSearchItem}
            />
          </VStack>
          <SavedSearchDialog
            filters={filters}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            searchToUpdate={itemToUpdate ?? undefined}
            canAdministrate={canAdministrate}
            groups={groups}
            currentLocation={location}
          />
          <ContextMenu
            id={`${SAVED_SEARCH_MENU_ID}##${location}`}
            menuItems={[
              {
                id: 'update',
                label: 'Edit',
              },
              {
                id: 'delete',
                label: 'Delete',
              },
            ]}
            onClick={handleMenuItemClicked}
          />
          <DeleteDialog
            open={Boolean(itemToDelete)}
            onClose={() => setItemToDelete(null)}
            onClick={handleDelete}
            title="Delete saved search?"
            message={
              <VStack alignItems="start">
                <p style={{ margin: '0' }}>
                  Are you sure you want to delete the saved search <em>{itemToDelete?.label}</em>?
                </p>
                {itemToDelete?.type === 'shared' && (
                  <p>This is a shared search and will no longer be available.</p>
                )}
                <p style={{ margin: '0' }}>Once deleted, the saved search cannot be restored.</p>
              </VStack>
            }
          />
        </>
      )}
    </>
  );
}
