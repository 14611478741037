import { useState } from 'react';

import { SearchParameters } from 'api/search/useSearch';
import CommandMenu from 'components/command/Command';
import { SearchItemTypeEnum } from 'types/graphqlTypes';

import CreateContact from './memberList/CreateContact';

function Contacts() {
  const [params, setParams] = useState<SearchParameters>({
    searchString: '',
    skip: false,
    forceMetadataMTypes: [SearchItemTypeEnum.contact],
    mdfId: 'contact-mdf',
    perPagelimit: 50,
    toolbarState: {
      assignedIds: [],
      createdByIds: [],
      defaultMdfId: 'contact-mdf',
      isFiltering: true,
      mdfId: 'contact-mdf',
      mTypes: [SearchItemTypeEnum.contact],
      order: 'asc',
      sortBy: 'title',
      statusFilter: [],
      showRestricted: false,
      rangeBy: null,
    },
  });

  return (
    <CommandMenu
      isMounted
      hideToolbar
      darkHeader
      allowStaticSearchOptions={false}
      location="contacts_sidebar"
      searchParams={params}
      setSearchParams={setParams}
      headerAppendSlot={<CreateContact startMetadata={params.metadataFilter ?? {}} />}
      availableSorts={['createdAt', 'updatedAt', 'title']}
    />
  );
}

export default Contacts;
